import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Footer from 'components/Footer'
import Header from 'components/Header'
import Home from 'pages/Home'
import Bridge from 'pages/Bridge'

const App: React.FC = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bridge" element={<Bridge />} />
        {/* <Route path="/pool" element={<Bridge />} /> */}
        {/* <Route path="/explorer" element={<Bridge />} /> */}
      </Routes>
      <Footer />
    </>
  )
}

export default App
