import BridgeForm from 'components/BridgeForm'
import {useNetwork,useBalance,useSwitchNetwork } from 'wagmi'
import './Bridge.scss'

const Bridge = () => {
  const { chain } = useNetwork()
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork()
 
  const renderHeader = (
    <div className="bridge-header">
      <h3>crosschain</h3>
      <div className="sc-imABML fdpUnK">
        <div className="set">
          <div className="sc-gbOuXE iERwTQ controls " style={{margin:"10px"}}>
          {chains.map((x) => {
          if(!switchNetwork || x.id !== chain?.id){
          return (<button className="sc-gZMcBi eZHVXX sc-kAzzGY sc-chPdSV eNCXlQ"
            disabled={!switchNetwork || x.id === chain?.id}
            key={x.id}
            onClick={() => switchNetwork?.(x.id)}
          >
            Switch To {x.name}
            {isLoading && pendingChainId === x.id && ' (switching)'}
          </button>)
          }
          }
        )}
          </div>
        </div>
      </div>
    </div>
  )



  
  return (
    <div className="bridge">
      <div className="mx pad">
        <div className="bridge-container">
          {renderHeader}
          <BridgeForm />
        </div>
      </div>
    </div>
  )
}
 

export default Bridge
