import React from "react";
import { Link } from "react-router-dom";
import { Web3Button } from "@web3modal/react";
import "./Header.scss";
import Logo from "assets/logo/8bitlogo.png";
import { useTransactionStore } from "store/transactionStore";
import Modal from "components/Modal";

import { ReactComponent as CloseIcon } from "../../assets/icons/Cross.svg";
import { ReactComponent as PendingIcon } from "../../assets/icons/pending.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";

const Header: React.FC = () => {
  const transactionStatus = useTransactionStore((store) => store.transactionStatus);
  const setTransactionStatus = useTransactionStore((store) => store.setTransactionStatus);

  return (
    <div className="mx">
      <div className="header">
        <Link to="/">
          <img src={Logo} alt="" width="70" height="70" />
        </Link>
        <Web3Button />
      </div>
      {transactionStatus && (
        <Modal isOpen>
          <div className={`transaction-modal ${transactionStatus.status}`}>
            <div className="transaction-modal-header">
              <div></div>
              <h3>{transactionStatus.title}</h3>
              {transactionStatus.status !== "PENDING" && (
                <div className="close-icon" onClick={() => setTransactionStatus(null)}>
                  <CloseIcon />
                </div>
              )}
            </div>
            <div className="transaction-modal-content">
              <div className={`icon ${transactionStatus.status}`}>
                {transactionStatus.status === "ERROR" ? (
                  <ErrorIcon />
                ) : transactionStatus.status === "SUCCESS" ? (
                  <SuccessIcon />
                ) : (
                  <PendingIcon />
                )}
              </div>
              <p>{transactionStatus.message}</p>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Header;
