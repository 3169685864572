import React from 'react'
import { Link } from 'react-router-dom'

import './Hero.scss'
import Button from 'components/Button'

const Hero: React.FC = () => {
  return (
    <div className="hero-wrapper">
      <div className="mx pad">
        <div className="hero-container">
          <div className="hero-content" data-aos="fade-up" data-aos-delay="100">
            <h1 style={{ fontSize: '45px' }}>
              Seamlessly Connecting BSC, Ethereum.
              <span className="dot">.</span>
            </h1>
            <p>
              Introducing RSFT Bridge: Revolutionizing cross-chain transfers for
              RSFT TOKEN, bridging Binance Smart Chain & Ethereum.

            </p>
             <p> Step-1: Click on Approve for approval of your token. </p>
    
             <p> Step-2: After Getting Approval click on Transfer Button to bridge your token succussfully</p>

            <div className="deposit-btn">
              <Link to="/bridge">
                <Button varient="primary">Enter Bridge</Button>
              </Link>
            </div>
          </div>
          {/* <div className="details">
            <div>
              <h2>62 Txns</h2>
              <p>Total bridged over time</p>
            </div>
            <div className="time">
              <div style={{ borderRight: '1px solid #fff' }}>
                <h2>1- 2 min</h2>
                <p>Transfer Time</p>
              </div>
              <div style={{ marginLeft: '2em' }}>
                <h2>0.1%</h2>
                <p>Bridge Fee</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Hero
