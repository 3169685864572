import { useEffect } from "react";
import { useState } from "react";
import "./BridgeForm.scss";
import bnbLogo from "../../assets/icons/bnb-logo.svg";
import ethLogo from "../../assets/icons/eth-logo.svg";
import logo from "../../assets/logo/8bitlogo.png";
import Config from "../../config.json";
import {
  useAccount,
  useContractRead,
  usePrepareContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
  useContractWrite,
} from "wagmi";
import tokenABI from "../../utils/abi/tokenABI.json";
import routerABI from "../../utils/abi/routerABI.json";
import Balance from "components/Balance";
import { useTransactionStore } from "store/transactionStore";

const BridgeForm = () => {
  const [tokenAmt, setTokenAmt] = useState(0);
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  const setTransactionStatus = useTransactionStore((store) => store.setTransactionStatus);
  const [isApproved, setIsApproved] = useState(false);

  //Get Token Balance
  const {
    data: token1,
    isLoading: token1Loding,
    refetch: refetchBalance,
  } = useContractRead({
    address:chain?.id===Config.foreignChainId? Config.foreignTokenUnderlyingContract as any:Config.homeTokenUnderlyingContract,
    abi: tokenABI,
    chainId: chain?.id,
    functionName: "balanceOf",
    args: [address],
  });

  // const { data: token2, isLoading: token2Loding } = useContractRead({
  //   address: Config.homeTokenUnderlyingContract as any,
  //   abi: tokenABI,
  //   chainId: Config.homeChainId,
  //   functionName: "balanceOf",
  //   args: [address],
  // });

  // Get Allowance

  const { data: tokenAllowance, isLoading: tokenAllowanceLoding } = useContractRead({
    address: (chain?.id === Config.foreignChainId
      ? Config.foreignTokenUnderlyingContract
      : Config.homeTokenUnderlyingContract) as any,
    abi: tokenABI,
    functionName: "allowance",
    args: [
      address,
      chain?.id === Config.foreignChainId
        ? Config.foreignRouterContract
        : Config.homeRouterContract,
    ],
  });

  const { config: approveConfig } = usePrepareContractWrite({
    address: (chain?.id === Config.foreignChainId
      ? Config.foreignTokenUnderlyingContract
      : Config.homeTokenUnderlyingContract) as any,
    abi: tokenABI,
    functionName: "approve",
    args: [
      chain?.id === Config.foreignChainId
        ? Config.foreignRouterContract
        : Config.homeRouterContract,
      (tokenAmt * 1e18).toLocaleString("fullwide", { useGrouping: false }),
    ],
  });

  const { data: approveData, write: approve } = useContractWrite(approveConfig);

  const {
    isLoading: isApproveLoading,
    isSuccess: isApproveSuccess,
    isError: isApproveError,
  } = useWaitForTransaction({
    hash: approveData?.hash,
  });

  const { config: swapConfig } = usePrepareContractWrite({
    address: (chain?.id === Config.homeChainId
      ? Config.homeRouterContract
      : Config.foreignRouterContract) as any,
    abi: routerABI,
    functionName: "SwapOutUnderlying",
    args: [
      chain?.id === Config.homeChainId ? Config.homeTokenContract : Config.foreignTokenContract,
      address,
      (tokenAmt * 1e18).toLocaleString("fullwide", { useGrouping: false }),
      chain?.id === Config.homeChainId ? Config.foreignChainId : Config.homeChainId,
    ],
  });

  const { data: swapData, write: swap, reset } = useContractWrite(swapConfig);
  const {
    isLoading: isSwapLoading,
    isError: isSwapError,
    isSuccess: isSwapSuccess,
  } = useWaitForTransaction({
    hash: swapData?.hash,
  });

  useEffect(() => {
    if (isSwapLoading) {
      setTransactionStatus({
        status: "PENDING",
        title: "Transfer",
        message: "Transaction is on pending. please wait",
      });
    }
    if (isSwapError) {
      setTransactionStatus({
        status: "ERROR",
        title: "Transfer",
        message: "Error while transferring.please try again",
      });

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
    if (isSwapSuccess) {
      swapData?.wait();
      console.log(chain?.id, swapData?.hash);
      let clock: any;
      clearInterval(clock);
      clock = setInterval(() => {
        fetch(`${Config.serverUrl}/tx?chainId=${chain?.id}&txHash=${swapData?.hash}`)
          .then((d) => d.json())
          .then((res) => {
            console.log("res:", res);
            if (res.data?.status === 1) {
              clearInterval(clock);
              setTransactionStatus({
                status: "SUCCESS",
                title: "Transfer",
                message: "Transaction confirmed",
              });
            } else if (res.data?.status === 0) {
              setTransactionStatus({
                status: "PENDING",
                title: "Transfer",
                message: "Transaction procesing by validator. please wait..",
              });
            } else {
              setTransactionStatus({
                status: "PENDING",
                title: "Transfer",
                message: "Transaction indexing please wait..",
              });
            }
          })
          .catch((e) => {
            clearInterval(clock);
            console.log("Error:", e);
          });
      }, 20000);

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
  }, [isSwapLoading, isSwapError, isSwapSuccess]);

  useEffect(() => {
    if (isApproveLoading) {
      setTransactionStatus({
        status: "PENDING",
        title: "Approve",
        message: "Transaction is on pending. please wait",
      });
    }
    if (isApproveError) {
      setTransactionStatus({
        status: "ERROR",
        title: "Approve",
        message: "Error while transferring.please try again",
      });

      setTimeout(() => {
        setTransactionStatus(null);
      }, 3000);
    }
    if (isApproveSuccess) {
      setTransactionStatus({
        status: "SUCCESS",
        title: "Approve",
        message: "Token Apporved Click on Transfer button to get your Token completely",
      });
      setTimeout(() => {
        setIsApproved(true);
        refetchBalance();
        reset();
        const tempAmt = tokenAmt;
        setTokenAmt((s) => s - s);
        setTokenAmt((s) => s + tempAmt);
        setTransactionStatus(null);
      }, 3000);
    }
  }, [isApproveSuccess, isApproveLoading, isApproveError]);

  return (
    <>
      <div className="bridge-form">
        <div id="selectCurrency" className="bridge-form-container">
          <div className="input_wrapper">
            <div className="form_header">
              <div className="form_header_container">
                <div className="form-header-title">From</div>
                <div className="sc-iybRtq fesqRF">
                  {/* <div className="form-header-title">Balance: {chain?.id===Config.foreignChainId?(Number(token1)/1e18)?.toString():(Number(token2)/1e18)?.toString()}</div> */}
                  <Balance
                    address={address}
                    chainId={chain?.id}
                    tokenAddress={
                      chain?.id === Config.foreignChainId
                        ? Config.foreignTokenUnderlyingContract
                        : Config.homeTokenUnderlyingContract
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form_input_container">
              <input
                className="input"
                title="Token Amount"
                type="number"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder="0.0"
                value={tokenAmt}
                onChange={(e) => setTokenAmt((e.target as any).value)}
              />
              <div className="token-selection-container" style={{ display: "relative" }}>
                <button className="open-currency-select-button">
                  <span className="sc-cmthru gHkGqU">
                    <div className="sc-hMFtBS token-logo">
                      <img src={logo} className="sc-esjQYD bHvMlI" alt="USDC" />
                    </div>
                    <span className="sc-bAeIUo gmcmlD token-symbol-container">
                      <h3>RSFT</h3>
                      <p>Token</p>
                    </span>
                  </span>
                </button>
                <button className="open-currency-select-button">
                  <span className="sc-cmthru gHkGqU">
                    {chain?.id === Config.foreignChainId ? (
                      <div className="sc-hMFtBS token-logo">
                        <img alt="BNB" src={bnbLogo} className="sc-esjQYD bHvMlI" />
                      </div>
                    ) : (
                      <div className="sc-hMFtBS token-logo">
                        <img alt="KAI" src={ethLogo} className="sc-esjQYD bHvMlI" />
                      </div>
                    )}

                    <span className="sc-bAeIUo gmcmlD token-symbol-container">
                      {chain?.id === Config.foreignChainId
                        ? "BNB CHAIN mainnet"
                        : "Ethereum Mainnet"}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-element">
          <div></div>
          <div
            className="sc-LKuAh cSFQVQ"
            style={{ marginLeft: "5em" }}
            onClick={() =>
              switchNetwork?.(
                chain?.id === Config.foreignChainId ? Config.homeChainId : Config.foreignChainId
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#C3C5CB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <polyline points="19 12 12 19 5 12"></polyline>
            </svg>
          </div>
          <div className="sc-LKuAh cSFQVQ">
            <div className="sc-gVLVqr keQqQO">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#C3C5CB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>{" "}
              <span>sendto</span>
            </div>
          </div>
        </div>
        <div id="selectChainID" className="bridge-form-container">
          <div className="input_wrapper">
            <div className="form_header">
              <div className="form_header_container">
                <div className="form-header-title">to</div>
                <Balance
                  address={address}
                  chainId={
                    chain?.id === Config.foreignChainId ? Config.homeChainId : Config.foreignChainId
                  }
                  tokenAddress={
                    chain?.id === Config.foreignChainId
                      ? Config.homeTokenUnderlyingContract
                      : Config.foreignTokenUnderlyingContract
                  }
                />
              </div>
            </div>
            <div className="form_input_container">
              <input
                className="input token-amount-input"
                type="number"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder="0.0"
                value={tokenAmt - 5 > 0 ? tokenAmt - 5 : 0}
              />
              <div className="token-selection-container">
                <button className="open-currency-select-button">
                  <span className="sc-cmthru gHkGqU">
                    <div className="sc-hMFtBS token-logo">
                      <img src={logo} className="sc-esjQYD bHvMlI" alt="USDC" />
                    </div>
                    <span className="sc-bAeIUo gmcmlD token-symbol-container">
                      <h3>RSFT</h3>
                      <p>Token</p>
                    </span>
                  </span>
                </button>
                <button className="open-currency-select-button">
                  <span className="sc-cmthru gHkGqU">
                    {chain?.id === Config.foreignChainId ? (
                      <div className="sc-hMFtBS token-logo">
                        <img alt="KAI" src={ethLogo} className="sc-esjQYD bHvMlI" />
                      </div>
                    ) : (
                      <div className="sc-hMFtBS token-logo">
                        <img alt="BNB" src={bnbLogo} className="sc-esjQYD bHvMlI" />
                      </div>
                    )}
                    <span className="sc-bAeIUo gmcmlD token-symbol-container">
                      {chain?.id === Config.foreignChainId
                        ? "Ethereum Mainnet"
                        : "BNB Chain Mainnet "}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reminders">
        <dl className="list">
          <dt>Reminder: Estimated Time of Crosschain Arrival is 10-30 min </dt>
          <dt>
            Minimum swap Amount :{" "}
            {Config.foreignChainId === chain?.id
              ? Config?.minimumSwapAmount?.foreign
              : Config?.minimumSwapAmount?.home}{" "}
            RSFT{" "}
          </dt>
          <dt>
            Fee :{" "}
            {Config.foreignChainId === chain?.id ? Config?.swapFee?.foreign : Config?.swapFee?.home}{" "}
            RSFT
          </dt>
        </dl>
      </div>
      <div className="controls">
        {isApproved || Number(tokenAllowance) / 1e18 >= tokenAmt ? (
          <button
            className="sc-gZMcBi eZHVXX sc-kAzzGY sc-chPdSV eNCXlQ"
            onClick={() => {
              if (tokenAmt >= 50) swap?.();
              else alert("minimum 50 Tokens swap!");
            }}
            disabled={isSwapLoading || !swap || tokenAmt < 50 || Number(token1) / 1e18 < tokenAmt}
          >
            {isSwapLoading
              ? "Sending..."
              : tokenAmt >= 50
              ? Number(token1) / 1e18 >= tokenAmt
                ? "Transfer"
                : "Insufficient Fund!"
              : " Min Swap 50 RSFT "}
          </button>
        ) : (
          <button
            className="sc-gZMcBi eZHVXX sc-kAzzGY sc-chPdSV eNCXlQ"
            onClick={() => approve?.()}
            disabled={
              isApproveLoading || !approve || tokenAmt < 50 || Number(token1) / 1e18 < tokenAmt
            }
          >
            {!isApproveLoading
              ? tokenAmt >= 50
                ? Number(token1) / 1e18 >= tokenAmt
                  ? "Approve"
                  : "Insufficient Fund!"
                : "Min Swap 50 RSFT"
              : "Approving... "}
          </button>
        )}
      </div>
    </>
  );
};

export default BridgeForm;
