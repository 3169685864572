
import { useContractRead } from 'wagmi'
import tokenABI from "../../utils/abi/tokenABI.json";

function Balance(props:any) {
    const { data: tokenAmt, isLoading: isLoding } = useContractRead({
        address: props.tokenAddress,
        abi: tokenABI,
        chainId: props.chainId,
        functionName: 'balanceOf',
        args: [props.address],
    });
    console.log(props.address,props.tokenAddress,props.chainId)
    return (
        <div className="form-header-title">
            Balance:{isLoding?"loading...":(Number(tokenAmt)/1e18)?.toString()}
        </div>
    )
}
export default Balance;